
import { defineComponent } from 'vue';
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
    name: 'DetailPage',
    components: {
    },
    data() {
        return {
            currentPath: this.$route.path,
        };
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        router.push({path: `/topics/${route.path}`});
    }
});
